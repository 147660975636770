import "./KeyMessage.css";

const PressAnyKey = () => {
  return (
    <div className="press-container">
        <p className="press-title">ENTER KEY - START GAME</p>
        <p className="press-subtitle">SPACE KEY - JUMP!</p>
        <p className="press-subtitle">BUY $MARIO FROM PUMP.FUN</p>
    </div>
  )
}
export default PressAnyKey