import { colors } from "@mui/material";
import "./Footer.css";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="copyright">
   
      <div style={{display:'flex',justifyContent:'center',gap:'20px',alignItems:'center'}}>
     
       <Link  to="https://pump.fun/CE8xPJFJpFtJQpje1uYVLsnfWqmuPWedXFMqqcPipump" target="_blank">  <span style={{
      color:'black',
      fontSize:'1rem'
     }}>Pump.fun</span> </Link>

<Link  to="https://twitter.com/solmariodotfun" target="_blank">  <span style={{
      color:'black',
      fontSize:'1rem'
     }}>Twitter</span> </Link>
       
       <Link  to="https://t.me/solmariofun" target="_blank">  <span style={{
      color:'black',
      fontSize:'1rem'
     }}>Telegram</span> </Link>


<span style={{
      color:'black',
      fontSize:'1rem'
     }}>Dex</span> 
    
      <div>
      </div>
     
      </div>
    </div>
  )
}
export default Footer